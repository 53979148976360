<template>
<v-dialog :value="invoiceModal" fullscreen persistent>
    <v-dialog
      @click:outside="closeSearchItemDialog"
      :value="searchItemDialog"
      hide-overlay
      width="500px"
    >
      <v-card height="300px">
        <search-item
          v-if="searchItemDialog"
          :searchString="searchString"
          :autoFocus="true"
          @setSearchString="setSearchString"
          @get-results="addOrderItem"
          @clear-input="clearItem"
        ></search-item>
      </v-card>
    </v-dialog>
      <v-card style="margin-bottom:120px !important">
        <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <div class="client-info mt-2">
              <fieldset class="fieldset text-left">
                <legend class="legend">Klant</legend>
                <div class="h1">
                  {{ thisInvoice.name }}
                  <v-icon
                    class="edit-client-icon"
                    @click="openEditClientModal"
                    color="primary"
                    large
                    >mdi-pencil-circle</v-icon
                  >
                </div>
                <div class="subtitle-1">
                  {{ thisInvoice.client.address }} <br>
                  {{ thisInvoice.client.postalcode }},
                  {{ thisInvoice.client.city }} <br>
                  {{ thisInvoice.client.country}}
                  <br />
                  {{ thisInvoice.client.phone }} <br />
                  {{ thisInvoice.client.mobile }}
                </div>
              </fieldset>
            </div>
          </v-col>
          <v-col cols="12" sm="2">
          </v-col>
          <v-col cols="12" sm="6">
          <v-row no-gutters>
              <v-col sm="4"></v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Factuurdatum
                    </legend>
                    {{ toDateString(thisInvoice.date) }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Vervaldatum
                    </legend>
                    {{ toDateString(thisInvoice.dueDate) }}
                  </fieldset>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="4"></v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Factuurnr
                    </legend>
                    {{ thisInvoice.invoiceNumber }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">
                      Status
                    </legend>
                    <v-chip
                      class="fieldset-status"
                      @click="incInvoiceStatus"
                      :color="invoiceStatuses[thisInvoice.status].color"
                      >{{ invoiceStatuses[thisInvoice.status].text }}</v-chip
                    >
                  </fieldset>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-text
          v-for="(order, index) in thisInvoice.orders"
          :key="order._id"
        >
          <fieldset>
            <b>Orderdatum:</b>
            {{ new Date(order.date).toLocaleDateString("nl-NL") }} |
            <b>Ordernr:</b> {{ order.orderNumber
            }}<v-icon small @click="openPromptModal(index)">mdi-delete</v-icon>
            <v-data-table
              dense
              :headers="itemHeaders"
              class="elevation-1"
              locale="nl"
              :items="order.items"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:[`item.name`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.name"
                  @save="saveItem"
                  @open="openItem"
                  @cancel="cancelItem"
                  @close="closeItem"
                >
                  {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.name"
                      @change="updateInvoiceItem(props.item, index)"
                      label="Naam"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.amount`]="props">
                <v-edit-dialog
                  @save="saveItem"
                  @open="openItem"
                  @cancel="cancelItem"
                  @close="closeItem"
                >
                  {{ props.item.amount }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.amount"
                      @focus="$event.target.select()"
                      @change="updateInvoiceItem(props.item, index)"
                      label="Aantal"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.unitAmount`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.unitAmount"
                  @save="saveItem"
                  @open="openItem"
                  @cancel="cancelItem"
                  @close="closeItem"
                >
                  {{ props.item.unitAmount }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.unitAmount"
                      @focus="$event.target.select()"
                      @change="updateInvoiceItem(props.item, index)"
                      label="Inhoud"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.price`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.price"
                  @save="saveItem"
                  @open="openItem"
                  @cancel="cancelItem"
                  @close="closeItem"
                >
                  {{ toCurrency(props.item.price) }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.price"
                      @focus="$event.target.select()"
                      @change="updateInvoiceItem(props.item, index)"
                      label="Prijs"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.VAT`]="{ item }">
                {{ item.VAT }}%
              </template>
              <template v-slot:[`item.netSubtotal`]="{ item }">{{
                toCurrency(item.netSubtotal)
              }}</template>
              <template v-slot:[`item.grossSubtotal`]="{ item }">{{
                toCurrency(item.grossSubtotal)
              }}</template>
              <template v-slot:[`item.discount`]="props">
                <v-edit-dialog
                  :return-value.sync="props.item.discount"
                  @save="saveItem"
                  @open="openItem"
                  @cancel="cancelItem"
                  @close="closeItem"
                >
                  {{ props.item.discount }}%
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.discount"
                      @change="updateInvoiceItem(props.item, index)"
                      label="BTW"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="deleteInvoiceItem(item, index)"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
            <v-row no-gutters justify="center">
              <div>
                <v-btn @click="openAddItemDialog(index)" color="primary">
                  <h1>+</h1>
                </v-btn>
              </div>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <table>
                  <tr>
                    <td>Aantal:</td>
                    <td>{{ order.items.length }}</td>
                  </tr>
                  <tr>
                    <td>Excl.</td>
                    <td>
                      {{ toCurrency(thisInvoice.orders[index].netTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Incl.</td>
                    <td>
                      {{ toCurrency(thisInvoice.orders[index].grossTotal) }}
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </fieldset>
        </v-card-text>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="6">
              <div>
                <table>
                  <tr>
                    <td>
                      Totaal excl.:
                    </td>
                    <td>
                      {{ toCurrency(invoiceNetTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Totaal incl.:
                    </td>
                    <td>
                      {{ toCurrency(invoiceGrossTotal) }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Betaald:</b> &nbsp;</td>
                    <td>
                      {{ toCurrency(invoiceCredit) }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Openstaand:</b> &nbsp;</td>
                    <td>
                      {{ toCurrency(invoiceDebit) }}
                    </td>
                  </tr>
                </table>

                <v-btn
                  class="d-md-none"
                  color="primary"
                  @click="invoicePaymentModal = true"
                  >Betaling</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-footer fixed height="120">
            <v-row no-gutters justify="center">
              <v-col cols="12" sm="3"></v-col>
              <v-col cols="12" sm="6" class="d-flex justify-center">
                <v-btn-toggle class="d-flex flex-wrap" style="background:none">
                  <v-btn @click="openPrintInvoiceModal" class="d-none d-sm-flex" color="primary"
                    >Afdrukken</v-btn
                  >
                  <v-btn @click="openEmailInvoiceModal" class="d-none d-sm-flex" color="primary">Mailen</v-btn>
                  <v-btn
                    class="d-none d-sm-flex"
                    color="primary"
                    @click="invoicePaymentModal = true"
                    >Betaling</v-btn
                  >
                  <v-btn color="primary" @click="openClientModal"
                    >Rekening</v-btn
                  >
                  <v-btn color="primary" @click="closeInvoiceModal"
                    >Annuleer</v-btn
                  >
                  <v-btn color="primary" @click="updateInvoice">Opslaan</v-btn>
                </v-btn-toggle>
              </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center">
                <span class="text-h6">Openstaand: {{ toCurrency(invoiceDebit) }}</span>
              </v-col>
            </v-row>
          </v-footer>
        </v-card-actions>
      </v-card>
    <client-modal
      v-if="clientModal"
      :clientModal="clientModal"
      :client="client"
      :tabNumber="3"
      @insert-client="insertClient"
      @delete-client="deleteClient"
      @close-client-modal="closeClientModal"
    ></client-modal>
    <invoice-payment-modal
      v-if="invoicePaymentModal"
      :invoicePaymentModal="invoicePaymentModal"
      :invoice="invoice"
      @insert-payments="insertInvoicePayments"
      @close-payment-modal="invoicePaymentModal = false"
    ></invoice-payment-modal>
    <prompt-modal
      v-if="promptModal"
      @confirm="deleteInvoiceOrder"
      @cancel="closePromptModal"
      promptType="deleteOrder"
      :data="thisInvoice.orders[orderIndex]"
      :promptModal="promptModal"
      promptTitle="Verwijder order"
      promptText="Weet u zeker dat u deze order wilt verwijderen?"
    ></prompt-modal>
    <edit-client-modal
      v-if="editClientModal"
      :editClientModal="editClientModal"
      :client="client"
      :invoice="invoice"
      @update-client="updateClient"
      @close-edit-client-modal="closeEditClientModal"
    ></edit-client-modal>
    </v-dialog>
</template>
<script>
import invoicePaymentModal from "./invoicePaymentModal"
import promptModal from "./promptModal"
import clientModal from "./clientModal"
import searchItem from "./searchItem"
import { toCurrency, toDateString } from "../js/functions.js"
import Big from "big.js"
import editClientModal from '@/components/editClientModal.vue';
export default {
  name: "invoiceModal",
  props: {
    invoice: Object,
    invoiceModal: Boolean
  },
  data() {
    return {
      thisInvoice: JSON.parse(JSON.stringify(this.invoice)),
      date: new Date(this.invoice.date).toISOString().split("T")[0],
      dateFormatted: this.formatDate(
        new Date(this.invoice.date).toISOString().split("T")[0]
      ),
      client: {},
      orderIndex: -1,
      searchString: "",
      clientModal: false,
      invoicePaymentModal: false,
      promptModal: false,
      datePicker: false,
      searchItemDialog: false,
      itemHeaders: [
        { text: "Naam", value: "name" },
        { text: "Aantal", value: "amount" },
        { text: "Eenheid", value: "unit" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Prijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Subtotaal", value: "netSubtotal" },
        { text: "Totaal incl.", value: "grossSubtotal" },
        { text: "Korting", value: "discount" },
        { text: "", value: "actions" }
      ],
      invoiceStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "Openstaand",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-marked-circle",
          color: "blue",
          text: "Betaald",
          length: 0
        }
      ],
      itemStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "In afwachting"
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling"
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid"
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "orange",
          text: "Geselecteerd"
        },
        {
          status: 4,
          icon: "mdi-checkbox-marked-circle",
          color: "light-green",
          text: "Label afgedrukt"
        }
      ],
      totalHeaders: [{ text: "Invoicetotaal", value: "grossTotal" }],
      payment: { amount: 0, payMethod: "", date: "" },
      editClientModal: false,
    }
  },
  methods: {
    changeToDot() {
      return 10
    },
    toDateString(date) {
      return toDateString(date)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    setSearchString(val) {
      this.searchString = val
    },
    clearItem() {
      this.searchString = ""
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    openPromptModal(index) {
      this.orderIndex = index
      this.promptModal = true
    },
    closePromptModal() {
      this.promptModal = false
    },
    insertInvoicePayments(payments) {
      this.thisInvoice.payments = payments
      this.payments = payments
      this.invoicePaymentModal = false
    },
    updateInvoice() {
      let invoiceVAT = []
      let confirmed = true
      let noZero = false
      this.thisInvoice.orders.forEach(order=>{
        order.items.forEach(item=>{
          if(item.amount==0)noZero = true
        })
      })
      if(noZero){
      confirmed = confirm("Er zijn nog artikelen die op 0 staan. Wilt u doorgaan?")
      }
      if(confirmed){
      if (this.thisInvoice.client.export) {
        invoiceVAT = [{ name: "Geen", amount: 0, total: this.invoiceNetTotal }]
      } else {
        invoiceVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
        for (let VAT of invoiceVAT) {
          VAT.total = this.thisInvoice.orders.reduce((acc, cur) => {
            return (
              acc +
              cur.VAT.filter(vat => vat.amount == VAT.amount).reduce(
                (acc2, cur2) => (acc2 * 100 + cur2.total * 100) / 100,
                0
              )
            )
          }, 0)
        }
      }
      let VAT = invoiceVAT.filter(VAT => parseFloat(VAT.total) != 0)
      this.thisInvoice.VAT = VAT
      let payments = this.thisInvoice.payments
      let totalPaid = payments.reduce(
        (acc, cur) => (acc * 100 + cur.amount * 100) / 100,
        0
      )
      this.thisInvoice.credit = totalPaid
      this.thisInvoice.debit = this.invoiceDebit
      if (this.thisInvoice.debit == 0) this.thisInvoice.status = 2
      this.thisInvoice.name = this.thisInvoice.client.name
      this.thisInvoice.totalProfit = this.totalProfit
      this.thisInvoice.netTotal = this.invoiceNetTotal
      this.thisInvoice.totalVAT = this.totalVAT
      this.thisInvoice.grossTotal = this.invoiceGrossTotal
      this.$emit("update-invoice", this.thisInvoice, false)
      this.closeInvoiceModal()
      return true
      }
    return false
    },
    insertClient() {},
    deleteClient() {},
    openClientModal() {
      this.client = this.thisInvoice.client
      this.clientModal = true
    },
    closeClientModal() {
      this.clientModal = false
      this.client = {}
    },
    commaToDot(val) {
      let string = val + ""
      let replace = string.replace(/,/g, ".")
      val = parseFloat(replace)
      return val
    },
    openAddItemDialog(index) {
      this.orderIndex = index
      this.searchItemDialog = true
    },
    addOrderItem(item) {
      let found = item.fixedPrices.find(
        fixed => fixed.clientNumber == this.thisInvoice.clientNumber
      )
      if (found) {
        item.price = found.price
      }
      let d = new Date(Date.now()).toISOString().split("T")
      d = d[0].split("-")
      let lotNumber = `${d[0].substr(-2)}/${d[1]}${d[2]}`
      item.lotNumber = item.lotNumber || lotNumber
      item.totalVAT = 0
      this.thisInvoice.orders[this.orderIndex].items.push(item)
      this.orderIndex = -1
      this.closeSearchItemDialog()
    },
    updateInvoiceItem(item, index) {
      item.amount =
        typeof item.amount === "string"
          ? item.amount.replace(",", ".")
          : item.amount.toString().replace(",", ".")
      item.unitAmount =
        typeof item.unitAmount === "string"
          ? item.unitAmount.replace(",", ".")
          : item.unitAmount.toString().replace(",", ".")
      item.price =
        typeof item.price === "string"
          ? item.price.replace(",", ".")
          : item.price.toString().replace(",", ".")
      try {
        let amount = Big(item.amount)
        let price = Big(item.price)
        let unitAmount = Big(item.unitAmount)
        let packages = Big()
        let totalWeight = Big()
        let netSubtotal = Big()
        let totalVAT = Big()
        let grossSubtotal = Big()
        let itemVAT = Big(item.VAT).div(100)
        if (this.thisInvoice.client.export) itemVAT = Big(0)
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2)
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount
                .times(price)
                .times(unitAmount)
                .round(2)
        if (parseFloat(item.discount) > 0)
          netSubtotal = netSubtotal.times(1 - Big(item.discount).div(100))
        if (this.thisInvoice.client.includeVAT)
          netSubtotal = netSubtotal.div(
            Big(item.VAT)
              .plus(100)
              .div(100)
          )
        totalVAT = netSubtotal.times(itemVAT)
        grossSubtotal = netSubtotal.plus(totalVAT)
        item.packages = packages.toNumber()
        item.totalWeight = totalWeight.toNumber()
        item.netSubtotal = netSubtotal.toNumber()
        item.totalVAT = totalVAT.toNumber()
        item.grossSubtotal = grossSubtotal.toNumber()
        item.totalCost =
          item.unit == "kg"
            ? item.amount * item.cost
            : item.amount * item.unitAmount * item.cost
        item.totalProfit = item.netSubtotal - item.totalCost
        this.thisInvoice.orders[index].netTotal = this.thisInvoice.orders[
          index
        ].items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
          0
        )
        let orderVAT
        if (this.thisInvoice.client.export) {
          orderVAT = [
            {
              name: "Geen",
              amount: 0,
              total: this.thisInvoice.orders[index].netTotal
            }
          ]
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of orderVAT) {
            VAT.total = this.thisInvoice.orders[index].items
              .filter(item2 => VAT.amount == item2.VAT)
              .reduce((acc, cur) => {
                if (cur.VAT == 0)
                  return (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100
                else return (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100
              }, 0)
          }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        this.thisInvoice.orders[index].VAT = VAT
        this.thisInvoice.orders[index].totalVAT = this.thisInvoice.orders[
          index
        ].items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
          0
        )
        this.thisInvoice.orders[index].grossTotal =
          (this.thisInvoice.orders[index].netTotal * 100 +
            this.thisInvoice.orders[index].totalVAT * 100) /
          100
      } catch (err) {
        console.error(err)
      }
    },
    saveItem() {},
    cancelItem() {},
    openItem() {},
    closeItem() {},
    deleteInvoiceOrder() {
      let index = this.orderIndex
      this.$store.state.ws.send(
        JSON.stringify({
          event: "deleteInvoiceOrder",
          data: { order_id: this.thisInvoice.orders[index]._id }
        })
      )
      this.thisInvoice.orders.splice(index, 1)
      this.orderIndex = -1
      this.promptModal = false
      return
    },
    deleteInvoiceItem(item, index) {
      item.amount = 0
      this.updateInvoiceItem(item, index)
      for (let i = 0; i < this.thisInvoice.orders[index].items.length; i++) {
        if (this.thisInvoice.orders[index].items[i]._id === item._id && this.thisInvoice.orders[index].items[i].name === item.name && this.thisInvoice.orders[index].items[i].amount === item.amount ) {
          this.thisInvoice.orders[index].items.splice(i, 1)
          break
        }
      }
    },
    closeInvoiceModal() {
      this.$emit("close-invoice-modal")
    },
    incInvoiceStatus() {
      if (this.thisInvoice.status < 2) {
        this.thisInvoice.status = this.thisInvoice.status == 0 ? 1 : 0
      }
    },
    deleteInvoice(id) {
      this.$emit("delete-invoice", id)
      this.closeInvoiceModal()
    },
    closeSearchItemDialog() {
      this.searchString = ""
      this.searchItemDialog = false
    },
    openPrintInvoiceModal() {
      let confirmed = this.updateInvoice()
      if(confirmed)this.$emit("open-print-invoice-modal",this.thisInvoice)
    },
    openEmailInvoiceModal(){
      let confirmed = this.updateInvoice()
      if(confirmed)this.$emit("open-email-invoice-modal",this.thisInvoice)
    },
    openEditClientModal() {
      this.editClientModal = true
      this.client = this.invoice.client
    },
    closeEditClientModal() {
      this.editClientModal = false
    },
    updateClient(client) {
      this.thisInvoice.client = client
      this.thisInvoice.clientNumber = client.clientNumber
      this.thisInvoice.name = client.name
      this.closeEditClientModal()
    }
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    routes() {
      return this.$store.state.settings.routes
    },
    payMethods() {
      return this.$store.state.settings.payMethods
    },
    totalProfit() {
      return this.thisInvoice.orders.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalProfit) * 100) / 100,
        0
      )
    },
    invoiceNetTotal() {
      return this.thisInvoice.orders.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.netTotal) * 100) / 100,
        0
      )
    },
    totalVAT() {
      return this.thisInvoice.orders.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
        0
      )
    },
    invoiceGrossTotal() {
      return (
        (parseFloat(this.invoiceNetTotal) * 100 +
          parseFloat(this.totalVAT) * 100) /
        100
      )
    },
    invoiceCredit() {
      return this.thisInvoice.payments.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.amount) * 100) / 100,
        0
      )
    },
    invoiceDebit() {
      return (this.invoiceGrossTotal * 100 - this.invoiceCredit * 100) / 100
    },
    statuses() {
      return this.$store.state.settings.invoiceStatuses
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val)
    }
  },
  components: { searchItem, invoicePaymentModal, promptModal, clientModal, editClientModal }
}
</script>
<style scoped>
.small-text-field {
  width: 33%;
  min-height: 70px;
}
.fieldset {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  padding: 5px;
  font-size: 1.2rem;
}
.fieldset-status {
  margin-top: -5px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}
.edit-client-icon {
  position: absolute;
  right: 0;
  top: 14px;
}
.client-info {
  position: relative;
}
</style>