<template>
  <v-dialog
    :value="emailInvoiceModal"
    persistent
    fullscreen
  >
    <v-card>
        <v-card-title>Email factuur</v-card-title>
      <v-row>
        <v-col cols="6">
            <input
              class="hidden"
              id="input"
              @focus="closeEmailInvoiceModal"
              type="text"
            />
         <div class="settings">
           <label><img src="/img/ideal.png" height="50" alt="ideal"></label
              ><input
                @change="viewInvoice"
                type="checkbox"
                v-model="options.qrcode"
              />
            <label>Aan:</label><input placeholder="Vul een emailadres in" type="text" v-model="headers.to" />
            <label>Herinnering:</label>
           <select @change="viewInvoice" v-model="options.reminderType">
             <option v-for="status of options.reminderTypes" :value="status.status" :key="status.status">{{status.text}}</option>
           </select>
           <label>Sjabloon:</label>
           <select @change="viewInvoice" v-model="options.invoiceTemplate">
             <option v-for="template of invoiceTemplates" :value="template.value" :key="template.text">{{template.text}}</option>
           </select>
            <label>Bericht:</label>
            <vue-editor v-if="dataUrl" v-model="headers.html"></vue-editor>
          </div>
        </v-col>
        <v-col cols="6">
          <iframe
            :src="dataUrl"
            frameborder="0"
            id="iframe"
          ></iframe>
        </v-col>

        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn @click="closeEmailInvoiceModal" color="primary"
              >Sluit</v-btn
            >
            <v-btn color="primary" @click="email">Mailen</v-btn>
          </v-btn-toggle>
        </v-footer>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { config } from "../config/config.mjs"
import { VueEditor } from "vue2-editor"
import { viewInvoice,emailInvoice } from "../js/pdfController"
import { errorHandler, fetchGET,fetchPOST, successHandler } from '../js/functions'
export default {
  name: "emailInvoiceModal",
  props: {
    emailInvoiceModal: Boolean,
    invoice: {}
  },
  data() {
    return {
      dataUrl: "",
      iframeLoaded: false,
      options:{
        stationary: false,
        qrcode: false,
        reminderType: 0,
        reminderTypes:[
        {status:0,text:"Geen"},
        {status:1,text:"Herinnering"},
        {status:2,text:"Aanmaning"}
      ],
        invoiceTemplate: null
      },
      headers: {
        subject: "",
        fileName: "",
        to: "",
        text: "",
        inHtml: "",
        html: ""
      }
    }
  },
  methods: {
    async email() {
      try {
        this.closeEmailInvoiceModal()
        let reminderType = this.options.reminderType > 0? this.options.reminderTypes[this.options.reminderType].text+": ": ""
        let buffer = await emailInvoice(
          this.invoice,
          this.options
        )
        this.headers.subject = `${reminderType}Factuur ${this.invoice.invoiceNumber}`
    this.headers.fileName = `Factuur ${this.invoice.invoiceNumber}.pdf`
        let response = await fetchPOST("sendMail", {
          buffer: buffer,
          headers: this.headers
        })
        if (response.success){
        let invoice = this.invoice
        invoice.emailed = true
        invoice.status = invoice.status <= 1 ? 1 : invoice.status
        await this.$store.dispatch("fetchPOST",{type:"invoiceEmailed",data:invoice})
          successHandler("Email verzonden")}
          else throw "Email niet verzonden"
      } catch (e) {
        errorHandler(e,"Email niet verzonden")
      }
    },
    closeEmailInvoiceModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-email-invoice-modal")
    },  
    async viewInvoice() {
      if(this.options.qrcode){
        this.headers.html+=`<p>Betaal nu gemakkelijk online</p><p><a style='padding:5px;line-height:50px;font-size:1.5em;height:50px;background:blue;color:white;-webkit-appearance:button;-moz-appearance:button;appearance:button;text-decoration:none;' href='${config.app.host}/createPayment/${this.invoice._id}'>Direct betalen met iDEAL</a></p><p><img src='${config.app.host}/img/ideal.png'/></p>`
      }
      else this.headers.html = this.headers.inHtml
      let data = await viewInvoice(this.invoice, this.options)
      this.dataUrl = URL.createObjectURL(data)
    }
  },
computed: {
  invoiceTemplates() {
    return this.$store.state.settings.invoiceTemplates 
  }
},
  async mounted() {
    let client = await fetchGET("findClient",this.invoice.clientNumber)
    this.headers.text = this.$store.state.settings.emailInvoiceBody.text
    this.headers.inHtml = this.$store.state.settings.emailInvoiceBody.html
    this.headers.html = this.$store.state.settings.emailInvoiceBody.html
    this.headers.to = client.email
    this.options.qrcode = this.$store.state.settings.ideal.enabled? true: false
    await this.viewInvoice(this.invoice, this.options)
  },
  components:{
    VueEditor
  }
}
</script>

<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
}
.quillWrapper {
  height: 400px;
}
input[type="checkbox"] {
  height: 1.6em;
  width: 1em;
}
input,select {
  border: 1px solid lightgray;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>